.PageTitle {
  font-size: 3em !important;
  font-weight: bold;
}

.Contact-Info {
  display: flex;
  justify-content: space-between;
}

.Appbar {
  min-height: 90px;
  position: relative !important;
}
.Toolbar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ImportantBtn,
.email-button {
  background-color: white !important;
  color: rgb(0, 94, 43) !important;
  border-radius: 32px !important;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
}

@media (max-width: 900px) {
  .ImportantBtn,
  .email-button {
    font-size: x-small !important;
  }
  .PageTitle {
    font-size: 2em !important;
  }
  .Toolbar {
    height: 120px;
  }
}

.ImportantBtn:hover,
.email-button:hover {
  background-color: rgb(0, 94, 43) !important;
  color: white !important;
  transition: 0.5s;
}
