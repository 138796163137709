.footer {
  padding-top: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.socialMedia {
  height: 25px;
  padding: 0 12px;
}

.right-footer {
  display: inline-flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  width: 20%;
}

.left-footer {
  display: inline-flex;
}
.footer-button {
  background-color: white !important;
  color: rgb(0, 94, 43) !important;
  border-radius: 32px !important;

  margin-bottom: 12px !important;
  padding: 24px !important;
  font-size: 12px;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
}
.footer-button:hover {
  background-color: rgb(0, 94, 43) !important;
  color: white !important;
  transition: 0.5s;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    padding: 8px;
  }
  .right-footer {
    width: auto;
  }
  .footer-button {
    flex-direction: column;
    height: 80px;
  }
}
