.about-us-text {
  font-size: 1.5em;
}

.about-us-text-box {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  width: 80%;
  margin: 0;
  gap: 24px;
  text-align: center;
}
