.qbackground {
  display: flex;
  justify-content: center;
  background-image: url(https://i.imgur.com/YUBBCha.jpg);
  background-repeat: round;
  align-items: center;
  flex-direction: column;
}
.form-box {
  height: 50em;
  width: 50em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  background-color: #005e2b;
  margin: 16px 0;
  border-radius: 24px;
  box-shadow: 8px 8px 8px 3px #531b1b;
}
.form-header {
  color: #ffffff;
  font-size: 2.5em;
  text-align: center;
}

.input-container {
  height: 80%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.text-field {
  position: relative;
  width: 90%;
}
label {
  position: absolute;
  top: 5px;
  left: 5px;
  color: rgb(255, 255, 255);
  pointer-events: none;
  transition: 0.3s ease-in-out;
  font-size: 14px;
}
input,
textarea {
  width: 100%;
  max-width: 100%;
  height: 35px;
  margin: 0px;
  background: #00682f;
  border: none;
  outline: none;
  color: white;
  border-bottom: rgb(255, 255, 255) 3px solid;
}

.button-container {
  display: flex;
  justify-content: center;
}
.form-button {
  background-color: white;
  color: rgb(0, 94, 43);
  border-radius: 32px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
  width: 50px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.form-button:hover {
  color: #fff;
  background-color: #005e2b;
}

input:focus + label,
input:valid + label,
textarea:focus + label,
textarea:valid + label {
  font-size: 10px;
  top: -10px;
}

.extra-space {
  height: 8em;
  width: 100%;
  background-image: url(https://i.imgur.com/WVO6J2C.jpg);
}

#message {
  height: 200px !important;
}

@media (max-width: 768px) {
  .form-box {
    width: 90%;
  }
  input,
  textarea {
    text-align: center;
  }
  .input-container {
    width: 90%;
  }
  .form-header {
    font-size: 1.8em;
  }
}
