.Card {
  margin: 30px;
  padding: 0;
  width: 25%;
  min-width: 300px;
  height: auto;
  font-family: "Petrona" !important;
}
.Cardbtn {
  justify-content: center;
}

.home-grid-button {
  background-color: white !important;
  color: rgb(0, 94, 43) !important;
  border-radius: 32px !important;
  padding: 8px 16px !important;
  font-size: 10px !important;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px !important;
}

.tree-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-image: url(https://i.imgur.com/WVO6J2C.jpg);
}
.home-grid-button:hover {
  background-color: rgb(0, 94, 43) !important;
  color: white !important;
  transition: 0.5s;
}
