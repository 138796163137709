.navbar {
  background-image: url(https://i.imgur.com/WVO6J2C.jpg);
  width: 100vw;
  height: auto;
  max-height: 300px;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  overflow-x: visible;
}
.nav-menu {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.nav-item {
  height: 5em;
  width: 15%;
  min-width: fit-content;
  background-color: transparent !important;
  border: none;
  border-radius: 32px !important;
  font-size: 90%;
  cursor: pointer !important;
  transition: background-color 0.5s ease !important;
}

.nav-item:hover {
  background-color: rgb(0, 94, 43) !important;
}
nav {
  display: none !important;
}

@media (max-width: 900px) {
  .nav-item {
    display: none !important;
  }
  nav {
    display: unset !important;
  }

  #menuToggle {
    display: block;
    position: relative;
    padding: 30px;
    z-index: 1;

    -webkit-user-select: none;
    user-select: none;
  }

  #menuToggle a {
    text-decoration: none;
    color: #fff;

    transition: color 0.3s ease;
  }

  #menuToggle input {
    display: block;
    width: 50px;
    height: 42px;
    position: absolute;
    top: 20px;
    left: 20px;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
  }

  /*
 * Just a quick hamburger
 */
  #menuToggle span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: #fff;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  #menuToggle span:first-child {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }

  /* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
  #menuToggle input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #fff;
  }

  /*
 * But let's hide the middle one.
 */
  #menuToggle input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  /*
 * Ohyeah and the last one should go the other direction
 */
  #menuToggle input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }

  /*
 * Make this absolute positioned
 * at the top left of the screen
 */
  #menu {
    position: absolute;
    padding-left: 25px;
    width: 100vw;
    right: 100vw;

    background: #005e2b;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform: translate(100vw, 0);

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  #menu li {
    padding: 10px 0;
    font-size: 22px;
    margin-left: 20px;
  }

  /*
 * And let's slide it in from the left
 */
  #menuToggle input:not(:checked) ~ ul {
    transform: none;
  }
}
