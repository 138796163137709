.newsletterBackground {
  background-image: url(https://i.imgur.com/4rRqR8a.jpg);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.newsletter-container {
  background-image: url(https://i.imgur.com/WVO6J2C.jpg);
  width: 50%;
  height: 300px;
  margin-top: 24px;
  box-shadow: 8px 8px 16px;
  border-radius: 24px;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.newsletter-header {
  font-size: 2em;
  color: white;
  text-align: center;
}
.newsletter-body {
  font-size: 1.2em;
  color: white;
  text-align: center;
  padding: 0 16px;
}

@media (max-width: 768px) {
  .newsletter-container {
    min-width: 80%;
    height: fit-content;
    min-height: 400px;
  }
}
