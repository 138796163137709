.TitleBox {
  background-image: url(https://i.imgur.com/qtx2khK.png);
  background-position: right;
  display: flex;
  justify-content: center;
}

.title-text {
  font-size: 6vw !important;

  background-color: rgba(0, 94, 43, 0.6);
  border-radius: 24px;
}

@media (max-width: 768px) {
  .title-text {
    font-size: 4em !important;
  }
}
