video {
  width: 100%;
}
.content {
  display: flex;
  height: 70%;

  width: 100%;
  position: absolute;
  align-items: center;
  justify-content: center;

  flex-direction: column;
}
.overlay {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.video-text {
  color: white;
  font-family: "Petrona";
  font-size: 3vw;
}
