@media (max-width: 768px) {
  .sign-up-form-desktop {
    display: none;
  }
  .sign-up-form-mobile {
    display: block !important;
    border: none;
    width: 360px;
    height: 500px;
    overflow: hidden;
  }
}



.ctct-inline-form{
  margin:  24px 0px;
  width: 50%;
  min-width: 300px;
  border-radius: 32px;
}