.ServiceBox {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-height: 20vw !important;
  width: 30%;
  text-align: center;
  margin-top: 5%;
  border-radius: 24px;
}
.ServiceBox:hover {
  background-color: white;
  color: #005e2b;
  transition: 0.5s;
}
.service-header {
  display: flex;
  align-items: center;
  border-bottom: solid;
  border-width: 2px;
  width: 100%;
  justify-content: center;
}
.header-text {
  font-size: 2vw;
}

.ServiceBody {
  font-size: 1.3vw !important;
  padding: 16px;
}
.box-row {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .ServiceBox {
    height: fit-content;
    width: 60%;
  }
  .ServiceBody {
    font-size: 1em !important;
  }
  .header-text {
    font-size: 30px;
  }
}
